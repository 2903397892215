import { NgModule } from '@angular/core';
import { DashboardMenuModule } from '@x/dashboard/menu';

@NgModule({
  imports: [
    DashboardMenuModule.forChild([
      {
        id: 'tax-categories',
        title: 'Tax Categories',
        parentId: 'configuration',
        route: '/admin/tax/tax-categories',
        icon: 'map-marked-alt',
        position: 5,
        permissions: ['tax_read'],
      },
      {
        id: 'tax-rates',
        title: 'Tax Rates',
        parentId: 'configuration',
        route: '/admin/tax/tax-rates',
        icon: 'money-bill',
        position: 6,
        permissions: ['tax_read'],
      },
    ]),
  ],
  exports: [DashboardMenuModule],
})
export class TaxMenuModule {}
