import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const AUTH_MENU: MenuItem[] = [
  {
    id: 'auth-roles',
    title: 'Auth Roles',
    parentId: 'configuration',
    route: '/admin/auth-roles',
    icon: 'user-shield',
    position: 6,
    permissions: ['auth_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(AUTH_MENU)],
})
export class AuthRoleMenuModule {}
