import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'voucher-program-index',
    title: 'Voucher Programs',
    route: '/admin/voucher/voucher-programs',
    icon: 'ticket-alt',
    parentId: 'configuration',
    position: 4,
    permissions: ['vouchers_read'],
  },
  {
    id: 'voucher-index',
    title: 'Vouchers',
    route: '/admin/voucher/vouchers',
    icon: 'ticket-alt',
    parentId: 'sales',
    permissions: ['vouchers_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(MENU_ITEMS)],
})
export class VoucherMenuModule {}
