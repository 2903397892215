import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const PRODUCTS_MENU: MenuItem[] = [
  {
    id: 'catalogue',
    title: 'Catalogue',
    icon: 'gem',
    position: 1,
  },
  // {
  //   id: 'product_dashboard',
  //   title: 'Dashboard',
  //   icon: 'gem',
  //   route: '/admin/products/stats',
  //   parentId: 'catalogue',
  //   permissions: ['products_read'],
  // },
  {
    id: 'products',
    title: 'Products',
    route: '/admin/products',
    icon: 'stroopwafel',
    parentId: 'catalogue',
    permissions: ['products_read'],
  },
  {
    id: 'product_options',
    title: 'Product Options',
    route: '/admin/products/options',
    icon: 'swatchbook',
    parentId: 'catalogue',
    permissions: ['products_read'],
  },
  {
    id: 'product_attributes',
    title: 'Product Attributes',
    route: '/admin/products/attributes',
    icon: 'pencil-ruler',
    parentId: 'catalogue',
    permissions: ['products_read'],
  },
  {
    id: 'product_associations',
    title: 'Product Associations',
    route: '/admin/products/associations',
    icon: 'link',
    parentId: 'catalogue',
    permissions: ['products_read'],
  },
  {
    id: 'taxons',
    title: 'Taxonomies',
    route: '/admin/products/taxons',
    icon: 'layer-group',
    parentId: 'catalogue',
    permissions: ['products_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(PRODUCTS_MENU)],
  exports: [DashboardMenuModule],
})
export class ProductsMenuModule {}
