import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const SAGE_MENU: MenuItem[] = [
  {
    id: 'integration',
    title: 'Integrations',
    icon: 'tags',
    position: 8,
  },
  {
    id: 'sage-order-sync',
    title: 'Sage Order Sync',
    route: '/admin/sage',
    icon: 'clipboard-list',
    parentId: 'integration',
    permissions: ['orders_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(SAGE_MENU)],
})
export class SageMenuModule {}
