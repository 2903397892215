import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/admin/sales-dashboard',
    pathMatch: 'full',
  },
  {
    path: 'demo',
    loadChildren: () => import('./demo/demo.module').then((m) => m.DemoModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('./admin/components/admin-home/admin-home.component').then(
        (m) => m.AdminHomeComponent,
      ),
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'sales-dashboard',
        pathMatch: 'full',
      },
      {
        path: 'sales-dashboard',
        loadComponent: () =>
          import('./admin/components/sales-dashboard/sales-dashboard.component').then(
            (m) => m.SalesDashboardComponent,
          ),
      },
      {
        path: 'products',
        loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: 'channels',
        loadChildren: () => import('./channel/channel.module').then((m) => m.ChannelModule),
      },
      {
        path: 'agreements',
        loadChildren: () => import('./agreement/agreement.module').then((m) => m.AgreementModule),
      },
      {
        path: 'geo-regions',
        loadChildren: () => import('./geo-region/geo-region.module').then((m) => m.GeoRegionModule),
      },
      {
        path: 'tax',
        loadChildren: () => import('./tax/tax.module').then((m) => m.TaxModule),
      },
      {
        path: 'payments',
        loadChildren: () => import('./payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: 'wallets',
        loadChildren: () => import('./wallets/wallets.module').then((m) => m.WalletsModule),
      },
      {
        path: 'promotions',
        loadChildren: () =>
          import('./promotions/promotions.module').then((m) => m.PromotionsModule),
      },
      {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then((m) => m.InventoryModule),
      },
      {
        path: 'logistics',
        loadChildren: () => import('./logistics/logistics.module').then((m) => m.LogisticsModule),
      },
      {
        path: 'voucher',
        loadChildren: () => import('./voucher/voucher.module').then((m) => m.VoucherModule),
      },
      {
        path: 'memberships',
        loadChildren: () =>
          import('./memberships/memberships.module').then((m) => m.MembershipsModule),
      },
      {
        path: 'subscriptions',
        loadChildren: () =>
          import('./subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
      },
      {
        path: 'auth-roles',
        loadChildren: () => import('./auth-role/auth-role.module').then((m) => m.AuthRoleModule),
      },
      {
        path: 'sage',
        loadChildren: () => import('./sage/sage.module').then((m) => m.SageModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'media',
        loadChildren: () => import('./media/media.module').then((m) => m.MediaModule),
      },
      {
        path: 'ads',
        loadChildren: () => import('./ad/ad.module').then((m) => m.AdModule),
      },
    ],
  },
  {
    path: '**',
    loadComponent: () =>
      import('./admin/components/admin-not-found/admin-not-found.component').then(
        (m) => m.AdminNotFoundComponent,
      ),
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
