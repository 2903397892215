import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const USERS_MENU: MenuItem[] = [
  {
    id: 'customers',
    title: 'Customers',
    icon: 'folder-user',
    position: 4,
  },
  {
    id: 'users',
    title: 'Users',
    route: '/admin/users',
    icon: 'users',
    parentId: 'customers',
    permissions: ['users_read'],
  },
  {
    id: 'wallets',
    title: 'Wallets',
    route: '/admin/wallets',
    icon: 'wallet',
    parentId: 'customers',
    permissions: ['wallets_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(USERS_MENU)],
})
export class UsersMenuModule {}
