import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  EcommerceBackendUrlModuleConfig,
  ECOMMERCE_BACKEND_CONFIG,
} from './ecommerce-backend-url.config';
import { EcommerceBackendUrlService } from './ecommerce-backend-url.service';

@NgModule({})
export class EcommerceBackendUrlModule {
  static forRoot(
    config: EcommerceBackendUrlModuleConfig,
  ): ModuleWithProviders<EcommerceBackendUrlModule> {
    return {
      ngModule: EcommerceBackendUrlModule,
      providers: [
        {
          provide: ECOMMERCE_BACKEND_CONFIG,
          useValue: config,
        },
        EcommerceBackendUrlService,
      ],
    };
  }
}
