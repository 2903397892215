import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Settings } from 'luxon';

@Component({
  selector: 'x-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-app',
  },
  standalone: true,
  imports: [RouterModule],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(LOCALE_ID)
    private locale: string,
  ) {}
  ngOnInit(): void {
    console.log('AppComponent Init', {
      locale: this.locale,
      clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      luxonTimezone: Settings.defaultZone,
      luxonLocale: Settings.defaultLocale,
    });
  }
}
