import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'notification-log-index',
    title: 'Notification Logs',
    route: '/admin/notifications/logs',
    icon: 'circle-exclamation',
    parentId: 'configuration',
    position: 8,
    // permissions: [], TODO
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(MENU_ITEMS)],
})
export class NotificationsMenuModule {}
