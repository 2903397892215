import { NgModule } from '@angular/core';
import { DashboardMenuModule } from '@x/dashboard/menu';

@NgModule({
  imports: [
    DashboardMenuModule.forChild([
      {
        id: 'georegions',
        title: 'Geo Regions',
        parentId: 'configuration',
        route: '/admin/geo-regions',
        icon: 'map-marked-alt',
        position: 2,
        permissions: ['georegion_read'],
      },
    ]),
  ],
  exports: [DashboardMenuModule],
})
export class GeoRegionMenuModule {}
