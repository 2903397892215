// organize-imports-ignore
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { Environment } from '@x/common/environment';
import { BrowserConsoleTransport, Log, LogLevel } from '@x/common/log';
import { AppModuleConfigFactory } from '@x/ecommerce-admin/app/app.module';
import { AppConfigFactory } from '@x/ecommerce-admin/config/app.config';
import { AppComponent } from '@x/ecommerce-admin/app/app.component';

Log.configure({
  level: LogLevel.TRACE,
  namespace: 'EcommerceAdmin',
  transport: new BrowserConsoleTransport(),
});

async function bootstrap() {
  const env = await Environment.initBrowser('/ecommerce/api/frontend/info/admin');

  console.log('Init Environment', env);

  if (Environment.bool('NG_PRODUCTION', false)) {
    enableProdMode();
  }
  const config = AppConfigFactory();
  const moduleConfig = AppModuleConfigFactory(config);

  await bootstrapApplication(AppComponent, moduleConfig);
}

function onBootstrapError(e: any) {
  Log.error('Application bootstrap error', e);
  const el: HTMLElement | null = document.querySelector('.bootstrap-error');
  if (el) el.style.display = 'block';
}

if (document.readyState === 'complete') {
  bootstrap().catch(onBootstrapError);
} else {
  document.addEventListener('DOMContentLoaded', () => bootstrap().catch(onBootstrapError));
}
