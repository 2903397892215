import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const ORDERS_MENU: MenuItem[] = [
  {
    id: 'sales',
    title: 'Sales',
    icon: 'tags',
    position: 3,
  },
  {
    id: 'orders',
    title: 'Orders',
    route: '/admin/orders',
    icon: 'clipboard-list',
    parentId: 'sales',
    permissions: ['orders_read'],
  },
  {
    id: 'bulk-orders',
    title: 'Bulk Orders',
    route: '/admin/orders/bulk',
    icon: 'table-list',
    parentId: 'sales',
    permissions: ['orders_read'],
  },
  {
    id: 'order-incidents',
    title: 'Order Incidents',
    route: '/admin/orders/incidents',
    icon: 'circle-exclamation-check',
    parentId: 'sales',
    permissions: ['order_incidents_read'],
  },
  {
    id: 'order-incident-categories',
    title: 'Order Incident Categories',
    route: '/admin/orders/incident-categories',
    icon: 'circle-exclamation-check',
    parentId: 'configuration',
    position: 7,
    permissions: ['order_incidents_read'],
  },
  {
    id: 'sales-dashboard',
    title: 'Sales Dashboard',
    route: '/admin/sales-dashboard',
    icon: 'chart-simple',
    parentId: 'sales',
    permissions: ['orders_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(ORDERS_MENU)],
})
export class OrdersMenuModule {}
