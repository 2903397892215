import { Inject, Injectable } from '@angular/core';
import {
  EcommerceBackendUrlModuleConfig,
  ECOMMERCE_BACKEND_CONFIG,
} from './ecommerce-backend-url.config';

@Injectable()
export class EcommerceBackendUrlService {
  private readonly baseUrl = this.config.baseUrl;

  constructor(
    @Inject(ECOMMERCE_BACKEND_CONFIG)
    private readonly config: EcommerceBackendUrlModuleConfig,
  ) {}

  url(path: string, options?: { query?: Record<string, any> }) {
    const params = options?.query ? new URLSearchParams(options.query) : undefined;

    const baseUrl = this.baseUrl.endsWith('/') ? this.baseUrl : this.baseUrl + '/';
    const clearPath = path.startsWith('/') ? path.substring(1) : path;

    return `${baseUrl}${clearPath}${params ? '?' + params.toString() : ''}`;
  }
}
