import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const INVENTORY_MENU: MenuItem[] = [
  {
    id: 'inventory',
    title: 'Inventory',
    icon: 'boxes',
    position: 4,
  },
  {
    id: 'warehouses',
    parentId: 'inventory',
    title: 'Warehouses',
    route: '/admin/inventory/warehouses',
    icon: 'warehouse',
    permissions: ['warehouse_read'],
  },
  {
    id: 'stock-items',
    parentId: 'inventory',
    title: 'Stock Items',
    route: '/admin/inventory/stock-items',
    icon: 'dolly-flatbed',
    permissions: ['stock_read'],
  },
  {
    id: 'stock-categories',
    parentId: 'inventory',
    title: 'Stock Categories',
    route: '/admin/inventory/stock-categories',
    icon: 'shelves',
    permissions: ['stock_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(INVENTORY_MENU)],
})
export class InventoryMenuModule {}
