import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 'memberships-index',
    title: 'Memberships',
    route: '/admin/memberships/memberships',
    icon: 'id-card',
    parentId: 'configuration',
    position: 3,
    permissions: ['members_read'],
  },
  {
    id: 'member-index',
    title: 'Members',
    route: '/admin/memberships/members',
    icon: 'id-card-alt',
    parentId: 'customers',
    permissions: ['members_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(MENU_ITEMS)],
})
export class MembershipsMenuModule {}
