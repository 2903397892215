import { Environment } from '@x/common/environment';
import { Settings } from 'luxon';
import type { AppConfig } from './app.config.type';

export function AppConfigFactory(): AppConfig {
  console.log('AppConfigFactory', Environment.all());

  const production = Environment.bool('NG_PRODUCTION', false);

  Settings.defaultLocale = Environment.str('LOCALE', 'en-GB');
  Settings.defaultZone = Environment.str('timezone', 'Europe/London');

  return {
    locale: Environment.str('LOCALE', 'en-GB'),
    timezone: Environment.str('timezone', 'Europe/London'),
    production: production,
    meta: {
      titleSuffix: Environment.requireStr('appName'),
    },
    cloudinary: {
      cloudName: Environment.requireStr('NG_CLOUDINARY'),
    },
    ngxs: {
      developmentMode: !production,
    },
    graph: {},
    channel: {},
    money: {
      format: {
        digitsInfo: '1.2-2',
        symbolDisplay: 'narrow',
      },
    },
    ecommerceBackend: {
      baseUrl: '/ecommerce/backend',
    },
    ecommerceDomainClient: {
      graphUrl: '/ecommerce/graphql',
    },
    contentClient: {
      graphUrl: '/content/graphql',
    },
    geocodeClient: {
      graphUrl: '/geocode/graphql',
    },
    sageClient: {
      graphUrl: '/sage/graphql',
    },
    aiClient: {
      graphUrl: '/ai/graphql',
    },
    admin: {
      flavor: Environment.requireStr('flavor'),
      appName: Environment.requireStr('appName'),
      logoUrl: Environment.requireStr('logoUrl'),
      logoWhiteUrl: Environment.requireStr('logoWhiteUrl'),
      backgroundImageUrl: Environment.requireStr('backgroundImageUrl'),
      mapDefaultLat: Environment.int('mapDefaultLat', 0),
      mapDefaultLng: Environment.int('mapDefaultLng', 0),
      mapDefaultZoom: Environment.int('mapDefaultZoom', 3),
    },
  };
}
