import { NgModule } from '@angular/core';
import { DashboardMenuModule } from '@x/dashboard/menu';

@NgModule({
  imports: [
    DashboardMenuModule.forChild([
      {
        id: 'configuration',
        title: 'Configuration',
        icon: 'cog',
        position: 100,
      },
      {
        id: 'channels',
        title: 'Channels',
        parentId: 'configuration',
        route: '/admin/channels',
        icon: 'store',
        position: 1,
        permissions: ['channels_read'],
      },
    ]),
  ],
  exports: [DashboardMenuModule],
})
export class ChannelMenuModule {}
