import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const MEDIA_MENU: MenuItem[] = [
  {
    id: 'ads',
    title: 'Ads',
    route: '/admin/ads',
    icon: 'grid-2-plus',
    parentId: 'catalogue',
    permissions: ['ad_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(MEDIA_MENU)],
  exports: [DashboardMenuModule],
})
export class AdMenuModule {}
