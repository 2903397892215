import {
  APP_BASE_HREF,
  CommonModule,
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipeConfig,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import {
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MatLuxonDateAdapterOptions,
} from '@angular/material-luxon-adapter';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { AiClientModule } from '@x/ai/client';
import { BrowserModule as CommonBrowserModule } from '@x/common/browser';
import { CloudinaryModule } from '@x/common/cloudinary';
import { DataModule } from '@x/common/data';
import { DateTimeModule, STRING_DATE_ADAPTER_OPTIONS } from '@x/common/datetime';
import { ErrorModule, ErrorResponseService } from '@x/common/error';
import { GraphModule } from '@x/common/graph';
import { IconModule } from '@x/common/icon';
import { LocaleModule } from '@x/common/locale';
import { MapModule } from '@x/common/map';
import { MAP_CONFIG } from '@x/common/map/map';
import { MetaModule } from '@x/common/meta';
import { MoneyModule } from '@x/common/money';
import { OperationModule } from '@x/common/operation';
import { StorageModule } from '@x/common/storage';
import { ContentClientModule } from '@x/content/client';
import { DashboardCoreModule } from '@x/dashboard/core';
import { DashboardDialogModule } from '@x/dashboard/dialog';
import { DashboardMediaModule } from '@x/dashboard/media';
import { DashboardMenuModule } from '@x/dashboard/menu';
import type { AppConfig } from '@x/ecommerce-admin/config/app.config.type';
import { GOOGLE_MAPS_CONFIG } from '@x/ecommerce-shop/config/map-config';
import { EcommerceBackendUrlModule } from '@x/ecommerce/backend-url';
import { EcommerceDomainClientModule } from '@x/ecommerce/domain-client';
import { EcommerceDomainDataModule } from '@x/ecommerce/domain-data';
import { GeocodeClientModule } from '@x/geocode/client';
import { SageClientModule } from '@x/sage/client';
import { AdMenuModule } from './ad/ad-menu.module';
import { AdModule } from './ad/ad.module';
import { ADMIN_MODULE_CONFIG } from './admin/admin.config';
import { AgreementMenuModule } from './agreement/agreement-menu.module';
import { AgreementSharedModule } from './agreement/agreement-shared.module';
import { AiModule } from './ai/ai.module';
import { APP_ICONS } from './app-icons';
import { AppRoutingModule } from './app-routing.module';
import { AuthRoleMenuModule } from './auth-role/auth-role-menu.module';
import { AuthRoleSharedModule } from './auth-role/auth-role-shared.module';
import { AuthSharedModule } from './auth/auth-shared.module';
import { DataViewManagerService } from './auth/services/data-view-manager.service';
import { ChannelMenuModule } from './channel/channel-menu.module';
import { ChannelSharedModule } from './channel/channel-shared.module';
import { ContentSharedModule } from './content/content-shared.module';
import { EcommerceAdminCoreModule } from './core/ecommerce-admin-core.module';
import { GeoRegionMenuModule } from './geo-region/geo-region-menu.module';
import { GeoRegionSharedModule } from './geo-region/geo-region-shared.module';
import { InventoryMenuModule } from './inventory/inventory-menu.module';
import { InventorySharedModule } from './inventory/inventory-shared.module';
import { LogisticsMenuModule } from './logistics/logistics-menu.module';
import { LogisticsSharedModule } from './logistics/logistics-shared.module';
import { MediaMenuModule } from './media/media-menu.module';
import { MembershipsMenuModule } from './memberships/memberships-menu.module';
import { MembershipsSharedModule } from './memberships/memberships-shared.module';
import { NotificationsMenuModule } from './notifications/notifications-menu.module';
import { NotificationsSharedModule } from './notifications/notifications-shared.module';
import { OrdersMenuModule } from './orders/orders-menu.module';
import { OrdersSharedModule } from './orders/orders-shared.module';
import { PaymentsMenuModule } from './payments/payments-menu.module';
import { ProductsMenuModule } from './products/products-menu.module';
import { ProductsSharedModule } from './products/products-shared.module';
import { PromotionsMenuModule } from './promotions/promotions-menu.module';
import { SageMenuModule } from './sage/sage-menu.module';
import { SubscriptionsMenuModule } from './subscriptions/subscriptions-menu.module';
import { SubscriptionsSharedModule } from './subscriptions/subscriptions-shared.module';
import { TaxMenuModule } from './tax/tax-menu.module';
import { TaxSharedModule } from './tax/tax-shared.module';
import { UsersMenuModule } from './users/users-menu.module';
import { UsersSharedModule } from './users/users-shared.module';
import { VoucherMenuModule } from './voucher/voucher-menu.module';
import { VoucherSharedModule } from './voucher/voucher-shared.module';
import { WalletsSharedModule } from './wallets/wallets-shared.module';

function appModuleProviders(config: AppConfig) {
  return importProvidersFrom(
    GeocodeClientModule.forRoot(config.geocodeClient),
    EcommerceDomainClientModule.forRoot(config.ecommerceDomainClient),
    EcommerceDomainDataModule.forRoot(config.channel),
    ContentClientModule.forRoot(config.contentClient),
    LocaleModule.forRoot(),
    NgxsModule.forRoot([], config.ngxs),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    GraphModule.forRoot(config.graph),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    // common
    CommonBrowserModule,
    DateTimeModule.forRoot({
      format: 'medium',
      timezone: config.timezone,
    }),
    MetaModule.forRoot(config.meta),
    CloudinaryModule.forRoot(config.cloudinary),
    StorageModule.forRoot({ prefix: 'x_ecommerce_admin_', version: 3 }),
    EcommerceBackendUrlModule.forRoot(config.ecommerceBackend),
    ErrorModule.forRoot(),
    OperationModule.forRoot(),
    IconModule.forRoot({
      fixedWidth: true,
      defaultPrefix: 'far',
      icons: APP_ICONS,
    }),
    DataModule.forRoot({
      viewStorageProvider: DataViewManagerService,
    }),
    DashboardCoreModule,
    DashboardDialogModule,
    DashboardMediaModule,
    DashboardMenuModule.forRoot([]),
    OperationModule.forRoot(),
    MoneyModule.forRoot(config.money),
    SageClientModule.forRoot(config.sageClient),
    AiClientModule.forRoot(config.aiClient),
    MapModule.forRoot({
      defaultCenter: {
        lat: config.admin.mapDefaultLat,
        lng: config.admin.mapDefaultLng,
      },
      defaultZoom: config.admin.mapDefaultZoom,
    }),
    // shared
    EcommerceAdminCoreModule,
    AgreementSharedModule,
    AuthSharedModule.forRoot(),
    AuthRoleSharedModule.forRoot(),
    ProductsSharedModule,
    ChannelSharedModule,
    GeoRegionSharedModule.forRoot(),
    TaxSharedModule.forRoot(),
    OrdersSharedModule,
    UsersSharedModule,
    InventorySharedModule,
    LogisticsSharedModule,
    VoucherSharedModule,
    MembershipsSharedModule,
    SubscriptionsSharedModule,
    WalletsSharedModule,
    NotificationsSharedModule,
    AiModule,
    AdModule,
    // menus
    ProductsMenuModule,
    PromotionsMenuModule,
    PaymentsMenuModule,
    OrdersMenuModule,
    UsersMenuModule,
    InventoryMenuModule,
    LogisticsMenuModule,
    ContentSharedModule,
    AgreementMenuModule,
    ChannelMenuModule,
    GeoRegionMenuModule,
    TaxMenuModule,
    VoucherMenuModule,
    AuthRoleMenuModule,
    MembershipsMenuModule,
    SubscriptionsMenuModule,
    FullCalendarModule,
    SageMenuModule,
    NotificationsMenuModule,
    MediaMenuModule,
    AdMenuModule,
  );
}

export function AppModuleConfigFactory(config: AppConfig): ApplicationConfig {
  return {
    providers: [
      { provide: APP_BASE_HREF, useValue: '/' },
      { provide: LOCALE_ID, useValue: config.locale },
      {
        provide: ErrorResponseService,
        useValue: new ErrorResponseService(),
      },
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: { appearance: 'fill', floatLabel: 'auto' } as MatFormFieldDefaultOptions,
      },
      {
        provide: MAP_CONFIG,
        useValue: GOOGLE_MAPS_CONFIG,
      },
      {
        provide: ADMIN_MODULE_CONFIG,
        useValue: config.admin,
      },
      { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
      {
        provide: STRING_DATE_ADAPTER_OPTIONS,
        useValue: {
          useUtc: false,
          defaultTimezone: config.timezone,
        },
      },
      {
        provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
        useValue: {
          useUtc: true,
          firstDayOfWeek: 0,
          defaultOutputCalendar: 'gregory',
        } satisfies MatLuxonDateAdapterOptions,
      },
      {
        provide: DATE_PIPE_DEFAULT_OPTIONS,
        useValue: {
          timezone: config.timezone,
          dateFormat: 'medium',
        } satisfies DatePipeConfig,
      },
      appModuleProviders(config),
    ],
  };
}
