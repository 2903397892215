import { NgModule } from '@angular/core';
import { DashboardMenuModule, MenuItem } from '@x/dashboard/menu';

export const MEDIA_MENU: MenuItem[] = [
  {
    id: 'media',
    title: 'Media',
    route: '/admin/media',
    icon: 'image-polaroid',
    parentId: 'catalogue',
    permissions: ['media_read'],
  },
];

@NgModule({
  imports: [DashboardMenuModule.forChild(MEDIA_MENU)],
  exports: [DashboardMenuModule],
})
export class MediaMenuModule {}
